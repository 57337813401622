<template>
    <div class="brand-list" v-loading="loading">
        <div class="banner-wrap">
            <img src="@/assets/images/banner-redarea.jpg"/>
        </div>

        <!-- 特色爆款 -->
        <div class="hot-goods">
            <div class="module-list-head" >
                <h2>
                    <span></span>特色爆款<label>（优质好物，产地直发）</label>
                </h2>
                <a class="change-a-bath" @click="changebath">换一批 >></a>
            </div>
            <div class="module-list-body">
                <div class="module-item"
                     v-for="(item, index) in hotGoodsList" :key="index"
                     @click="$router.pushToTab({ path: '/sku-' + item.sku_id+ '?source_type='+filters.website_type})">
                    <div class="module-goods-wrap">
                        <div class="module-img-wrap">
                            <img alt="商品图片" :src="$img(item.sku_image)" @error="imageError(index)" />
                            <img class="bgImg" src="@/assets/images/goods-redarea-bg.png"/>
                        </div>
                        <h3>{{ item.sku_name }}</h3>
<!--                        <p class="desc" v-if="item.spec_name">{{ item.spec_name }}</p>-->
                        <p class="price">
                            <span class="num">￥{{ item.price }}</span>
                        </p>
                        <butoon>立即购买</butoon>
                    </div>
                </div>
                <div class="ns-text-align" style="line-height: 300px;" v-if="(hotGoodsList&&hotGoodsList.length>0)?false:true"><i class="el-icon-shopping-cart-full"></i>没有找到您想要的商品。换个条件试试吧！</div>
            </div>
        </div>

        <!-- 湖南=江西 -->
        <div class="subarea">
            <div class="subarea-goods">
                <div class="subarea-list-head" >
                    <h2><span></span>湖南特色好物
                        <router-link to="/promotion/redarea/hunan" v-show="(csGoodsList&&csGoodsList.length>=goodPageSize)?true:false"><label>更多 >></label></router-link>
                    </h2>
                </div>
                <div class="subarea-list-body">
                    <div class="subarea-item"
                         v-for="(item, index) in csGoodsList" :key="index"
                         @click="$router.pushToTab({ path: '/sku-' + item.sku_id+ '?source_type='+filters.website_type})">
                        <div class="subarea-goods-wrap">
                            <div class="subarea-img-wrap">
                                <img alt="商品图片" :src="$img(item.sku_image)" @error="imageError(index)" />
                                <img class="bgImg" src="@/assets/images/goods-redarea-bg.png"/>
                            </div>
                            <h3>{{ item.sku_name }}</h3>
<!--                            <p class="desc" v-if="item.spec_name">{{ item.spec_name }}</p>-->
                            <p class="price">
                                <span class="num">￥{{ item.price }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="ns-text-align" v-if="(csGoodsList&&csGoodsList.length>0)?false:true"><i class="el-icon-shopping-cart-full"></i>没有找到您想要的商品。换个条件试试吧！</div>
                </div>
            </div>
            <div class="subarea-goods">
                <div class="subarea-list-head" >
                    <h2><span></span>江西特色好物
                        <router-link to="/promotion/redarea/jiangxi" v-show="(jxGoodsList&&jxGoodsList.length>=goodPageSize)?true:false"><label>更多 >></label></router-link>
                    </h2>
                </div>
                <div class="subarea-list-body">
                    <div class="subarea-item"
                            v-for="(item, index) in jxGoodsList" :key="index"
                            @click="$router.pushToTab({ path: '/sku-' + item.sku_id+ '?source_type='+filters.website_type})">
                        <div class="subarea-goods-wrap">
                            <div class="subarea-img-wrap">
                                <img alt="商品图片" :src="$img(item.sku_image)" @error="imageError(index)" />
                                <img class="bgImg" src="@/assets/images/goods-redarea-bg.png"/>
                            </div>
                            <h3>{{ item.sku_name }}</h3>
<!--                            <p class="desc" v-if="item.spec_name">{{ item.spec_name }}</p>-->
                            <p class="price">
                                <span class="num">￥{{ item.price }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="ns-text-align" v-if="(jxGoodsList&&jxGoodsList.length>0)?false:true"><i class="el-icon-shopping-cart-full"></i>没有找到您想要的商品。换个条件试试吧！</div>
                </div>
            </div>
        </div>

        <div class="feed-tab-wrapper">
            <ul class="feed-tab" >
                <li class="feed-tab__item" :class="filters.category_id === 0? 'feed-tab__item--active' : ''" @click="selectedAllCategoryGoods()">
                    <div class="feed-tab__item-title">
                        <img v-if="filters.category_id === 0" src="@/assets/images/list-red.png" style="width:19px;height:16px;margin-right:5px;margin-top:-1px"/>
                        <img v-else src="@/assets/images/list.png"  style="width:19px;height:16px;margin-right:5px;margin-top:-1px" />
                        <span class="feed-tab__item-title-text">全部</span>
                    </div>
                </li>
                <li class="feed-tab__item" v-for="(item, index) in categoryList" :key="index" :class="filters.category_id === item.category_id ? 'feed-tab__item--active' : ''" @click="selectedCategoryGoods(item)">
                    <div class="feed-tab__item-title"><span class="feed-tab__item-title-text">{{item.category_name}}</span></div>
                </li>
            </ul>
        </div>

        <div class="list-wrap">
            <div class="cargo-list" v-if="cargoList.length">
                <div class="goods-info">
                    <div
                            class="item"
                            v-for="(item, index) in cargoList" :key="index"
                            @click="$router.pushToTab({ path: '/sku-' + item.sku_id+ '?source_type='+filters.website_type})">
                        <div class="goods-wrap">
                            <div class="img-wrap">
                                <img alt="商品图片" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" />
                                <img class="bgImg" src="@/assets/images/goods-redarea-bg.png"/>
                            </div>
                            <h3>{{ item.sku_name }}</h3>
<!--                            <p class="desc" v-if="item.des">{{ item.spec_name }}</p>-->
                            <p class="price">
                                <span class="num">￥{{ item.price }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="pager">
                    <el-pagination
                            background
                            :pager-count="5"
                            :total="total"
                            prev-text="上一页"
                            next-text="下一页"
                            :current-page.sync="currentPage"
                            :page-size.sync="pageSize"
                            @size-change="handlePageSizeChange"
                            @current-change="handleCurrentPageChange"
                            hide-on-single-page
                    ></el-pagination>
                </div>
            </div>
            <div class="empty" v-else>
                <div class="ns-text-align">没有找到您想要的商品。换个条件试试吧</div>
            </div>
        </div>
    </div>

</template>

<script>
    import { getXghGoodsHot, getXghGoodsGood, getXghGoodsLists} from "@/api/goods/goods";
    import { getChildCategory } from "@/api/goods/goodscategory";

    export default {
        name: "list",
        components: {
        },
        inject:["reload"],
        computed: {},
        data: () => {
            return {
                total: 0,
                currentPage: 1,
                pageSize: 20,
                goodPageSize : 6,
                categoryList:[],
                cargoList:[],
                csGoodsList:[],
                jxGoodsList:[],
                hotGoodsList:[],
                clickhotpagecount:1,
                hotPageCount:0,
                filters: {
                    website_id: 0,
                    website_type: 0,
                    site_id: 0,
                    type_id:0,
                    category_id: 0,
                },
                loading: true,
            }
        },
        created() {

        },
        mounted() {
            this.getCategory() //菜单
            this.getGoodsList() //商品列表
            this.getXghGoodsGoodList('430000000000') //湖南特色好物
            this.getXghGoodsGoodList('360000000000') //江西特色好物
            this.getXghGoodsHotList(1) //爆款
        },
        methods: {
            selectedCategoryGoods(item){
                this.filters.category_id = item.category_id
                this.currentPage =1
                this.getGoodsList() //产品列表
            },
            selectedAllCategoryGoods(){
                this.filters.category_id = 0
                this.currentPage =1
                this.getGoodsList() //产品列表
            },
            getCategory(){
                getChildCategory({
                    level: 1,
                    pid: 0
                }).then(res => {
                    if (res.code == 0 && res.data) {
                        // console.log('分类数据', res.data)
                        this.categoryList = res.data                }
                })
                    .catch(err => {
                        this.$message.error(err.message)
                    })
            },
            getGoodsList() {
                const params = {
                    page: this.currentPage,
                    page_size: this.pageSize,
                    ...this.filters
                }
                getXghGoodsLists(params || {})
                    .then(res => {
                        // console.log('商品列表',res);
                        const { count, page_count, list } = res.data
                        this.total = count
                        this.cargoList = list
                        this.loading = false
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },
            getXghGoodsGoodList(area_id) {
                const params = {
                    page: 1,
                    page_size: this.goodPageSize,
                    area_id:area_id
                }
                getXghGoodsGood(params || {})
                    .then(res => {
                        // console.log('特色好物',res);
                        if(area_id == '430000000000'){
                            this.csGoodsList = res.data.list
                        }else if(area_id == '360000000000'){
                            this.jxGoodsList = res.data.list
                        }
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },
            changebath(){
                let count = this.clickhotpagecount+1
                if(this.clickhotpagecount < this.hotPageCount){
                    this.getXghGoodsHotList(count)
                    this.clickhotpagecount = count
                }else{
                    this.getXghGoodsHotList(1)
                    this.clickhotpagecount = 1
                }
            },
            getXghGoodsHotList(page) {
                const params = {
                    page: page,
                    page_size: 3
                }
                getXghGoodsHot(params || {})
                    .then(res => {
                        // console.log('爆款',res);
                        this.hotPageCount = res.data.page_count
                        this.hotGoodsList = res.data.list
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },
            handlePageSizeChange(size) {
                this.pageSize = size
                this.currentPage =1
                this.getXghGoodsLists()
            },
            handleCurrentPageChange(page) {
                this.currentPage = page
                this.getXghGoodsLists()
            },
            imageError(index) {
                this.cargoList[index].sku_image = this.defaultGoodsImage;
            },
        }
    };
</script>

<style lang="scss">
    #app{
        background-color: #f7f7f7;
    }
    #app .el-main{
        width: 100%;
    }
    .banner-wrap{
        width:100%;
        height:450px;
        margin:0;
        padding:0;
        position: relative;
        img{
            height:450px;
            width: 100%;
            /*position: absolute;
            width: 1920px;
            max-width: 1920px;
            margin-left: -960px;
            top:0;
            left: 50%;*/
        }
    }
    .areaFilters{
        width: 1210px;
        background: #fff;
        margin: 20px auto;
        padding: 10px 0px 15px 0px;
        min-height: 60px;
        position: relative;
        border-radius: 8px;
        background-color: #fff;
        .areaFilters--label{
            float: left;
            margin-right: 10px;
            line-height: 30px;
            text-align: right;
            font-size: 18px;
            color: #D81E01;
            font-weight:600;
            width: 60px;
            display: block;
        }
        .areaFilters--content {
            float: left;
            width: 1140px;
            margin-bottom: 5px;
            display: block;
            .items {
                display: inline-block;
                padding: 0 10px;
                cursor: pointer;
                font-size: 16px;
                line-height: 30px;
                color:#999;
            }
            .items:hover,.items.active {
                color: #d81e01;
            }
        }
        .clear{
            clear:both;
            zoom: 1;
        }
    }

    .feed-tab-wrapper{
        width: 1170px;
        padding:0px 20px;
        margin: 0 auto;
        background-color:#D81E01 ;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        .feed-tab {
            width: 1170px;
            margin: 0 auto;
            height: 48px;
            display: flex;
            .feed-tab__item {
                position: relative;
                height: 42px;
                margin-top:6px;
                width: 234px;
                float: left;
                text-align: center;
                cursor: pointer;
                .feed-tab__item-title {
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 42px;
                }
                .feed-tab__item-icon{
                    width: 18px;
                    height: 18px;
                    margin-right: 5px;
                    margin-top: -2px;
                }
            }
            .feed-tab__item.feed-tab__item--active{
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                background-color: #fff;
                .feed-tab__item-title {
                    color: #D81E01;
                    font-size: 17px;
                }
            }
        }
    }

    .subarea{
        width: 1210px;
        display: flex;
        margin: 0 auto;
        .subarea-goods {
            width: 590px;
            display: block;
            margin: 20px auto;
            padding: 10px;
            background-color: #fff;
            border-radius: 8px;
            border-radius: 8px;

            .subarea-list-head {
                padding: 0px 10px;
                margin: 0;

                h2 {
                    line-height: 42px;
                    color: #333;
                    padding: 10px 0px;
                    font-size: 22px;
                    cursor: pointer;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: left;
                    vertical-align: middle;
                    display: inline-block;
                    width:100%;

                    label{
                        float: right;
                        font-size: 12px;
                        font-weight: normal;
                        cursor: pointer;
                    }

                    span {
                        border: 4px solid $base-color;
                        border-radius: 6px;
                        height: 18px;
                        margin: 0px 10px;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }

            .subarea-list-body {
                display: flex;
                flex-wrap: wrap;
                .subarea-item {
                    width: 33.33%;
                    margin: 0;
                    background: #fff;
                    cursor: pointer;
                    padding:0;
                    transition: all 0.2s linear;
                    .subarea-goods-wrap{
                        margin: 0 11px 20px;
                        padding: 0px 0px 10px;
                        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
                        border-radius: 8px;
                        height:248px;
                        width: 168px;

                        &:hover {
                            z-index: 2;
                            -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                            box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                            -webkit-transform: translate3d(0, -2px, 0);
                            transform: translate3d(0, -2px, 0);
                        }

                        .subarea-img-wrap {
                            width: 168px;
                            height: 168px;
                            margin: 0px auto;
                            text-align: center;
                            overflow: hidden;
                            position:relative;
                            img {
                                width: 168px;
                                height: 168px;
                                z-index: 1;
                                border: none;
                                position: absolute;
                                margin-right: -75px;
                                top:50%;
                                left:50%;
                                display: block;
                                transform: translate(-50%,-50%);
                                border-top-left-radius: 8px;
                                border-top-right-radius: 8px;
                            }
                        }
                        h3 {
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 22px;
                            height: 44px;
                            text-align: left;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            margin: 10px 10px 5px;
                        }
                        .desc {
                            margin:0 10px;
                            height:14px;
                            font-size: 13px;
                            line-height:14px;
                            color: #b0b0b0;
                            text-align: left;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                        .price {
                            margin:0 10px;
                            font-size: 16px;
                            text-align: left;
                            font-weight: 500;
                            color: $base-color;
                            del {
                                margin-left: 0.5em;
                                color: #b0b0b0;
                            }
                        }
                    }
                }
            }
        }
        .subarea-goods:nth-child(1){
            margin-right: 15px;
        }
        .subarea-goods:nth-child(2){
            margin-left: 15px;
        }
    }

    .hot-goods {
        width: 1210px;
        display: block;
        margin: 20px auto;
        padding: 10px 0px;
        background-color: #fff;
        border-radius: 8px;
        border-radius: 8px;

        .module-list-head {
            padding: 0px 10px;
            margin: 0;
            position: relative;

            .change-a-bath{
                position: absolute;
                width: 120px;
                text-align: right;
                right:30px;
                top:30px;
                font-size: 12px;
                color:#636363;
                cursor: pointer
            }

            .change-a-bath:hover{
                color: $base-color;
            }

            h2 {
                line-height: 42px;
                color: #333;
                padding: 10px 0px;
                font-size: 22px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: left;
                vertical-align: middle;
                display: inline-block;

                label{
                    font-size: 16px;
                    font-weight: normal;
                }

                span {
                    border: 4px solid $base-color;
                    border-radius: 6px;
                    height: 18px;
                    margin: 0px 10px;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }

        .module-list-body {
            display: flex;
            flex-wrap: wrap;

            .module-item {
                width: 33.33%;
                margin: 0;
                background: #fff;
                cursor: pointer;
                padding:0;
                transition: all 0.2s linear;
                .module-goods-wrap{
                    margin: 0 20px 20px;
                    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
                    border-radius: 8px;
                    height:168px;
                    padding-left: 168px;
                    position:relative;

                    &:hover {
                        z-index: 2;
                        -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                        -webkit-transform: translate3d(0, -2px, 0);
                        transform: translate3d(0, -2px, 0);
                    }

                    .module-img-wrap {
                        width: 168px;
                        height: 168px;
                        position: absolute;
                        left:0;
                        top:0;
                        margin: 0px auto;
                        text-align: center;
                        overflow: hidden;
                        img {
                            width: 168px;
                            height: 168px;
                            z-index: 1;
                            border: none;
                            position: absolute;
                            margin-right: -75px;
                            top:50%;
                            left:50%;
                            display: block;
                            transform: translate(-50%,-50%);
                            border-top-left-radius: 8px;
                            border-bottom-left-radius: 8px;
                        }
                    }
                    h3 {
                        font-weight: normal;
                        font-size: 15px;
                        line-height: 22px;
                        height: 46px;
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        margin: 0px 15px 5px;
                        padding-top: 30px;
                    }
                    .desc {
                        margin:0 10px;
                        height:14px;
                        font-size: 13px;
                        line-height:14px;
                        color: #b0b0b0;
                        text-align: left;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .price {
                        margin:0 10px;
                        font-size: 16px;
                        text-align: left;
                        font-weight: 500;
                        color: $base-color;
                        del {
                            margin-left: 0.5em;
                            color: #b0b0b0;
                        }
                    }

                    butoon{
                        background-color: $base-color;
                        width: 41%;
                        color: #fff;
                        font-size: 12px;
                        margin: 10px 5px 10px 12px;
                        display: block;
                        line-height: 16px;
                        text-align: center;
                        border-radius: 6px;
                        padding: 5px 10px;
                    }
                }
            }
        }
    }

    .list-wrap {
        width: 1190px;
        margin: 0 auto;
        overflow: hidden;
        min-height: 500px;
        background-color:#fff;
        padding: 10px 10px 20px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-bottom: 30px;
        .goods-info {
            margin-top: 5px;
            display: flex;
            flex-wrap: wrap;
            .item {
                width: 20%;
                margin: 0;
                background: #fff;
                cursor: pointer;
                padding:0;
                transition: all 0.2s linear;
                .goods-wrap{
                    margin: 0 11px 20px;
                    padding: 0px 0px 10px;
                    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
                    border-radius: 8px;
                    height:300px;

                    &:hover {
                        z-index: 2;
                        -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                        -webkit-transform: translate3d(0, -2px, 0);
                        transform: translate3d(0, -2px, 0);
                    }

                    .img-wrap {
                        width: 216px;
                        height: 216px;
                        margin: 0px auto;
                        text-align: center;
                        overflow: hidden;
                        position:relative;
                        img {
                            width: 216px;
                            height: 216px;
                            z-index: 1;
                            border: none;
                            position: absolute;
                            margin-right: -75px;
                            top:50%;
                            left:50%;
                            display: block;
                            transform: translate(-50%,-50%);
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                        }
                    }
                    h3 {
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 22px;
                        height: 44px;
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        margin: 10px 10px 5px;
                    }
                    .desc {
                        margin:0 10px;
                        height:14px;
                        font-size: 13px;
                        line-height:14px;
                        color: #b0b0b0;
                        text-align: left;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .price {
                        margin:0 10px;
                        font-size: 16px;
                        text-align: left;
                        font-weight: 500;
                        color: $base-color;
                        del {
                            margin-left: 0.5em;
                            color: #b0b0b0;
                        }
                    }
                }
            }
        }
    }
    .empty {
        margin-top: 45px;
    }
    .pager {
        text-align: center;
        margin: 15px 0;
    }

    .ns-text-align{
        width: 100%;
        line-height: 200px;
    }
</style>
